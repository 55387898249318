<template>
	<div class="flexbox-wrapper wrapper" id="products">
        <p class="angebot mobile">
					Angebot
				</p>
		<div class="flexbox left">
			<img
				class="itemIMG firstIMG"
				src="/img/ES_Explo_Render_alpha.png"
			/>
			<div class="flexboxText">
				<p class="itemHeadline">Interaktives Lernen</p>
				<p class="itemText firstText">
					Der Satellitenbaukasten bietet eine einzigartige
					Möglichkeit, die Form und Funktion von Satelliten
					durch hands-on Erfahrung zu erlernen. Jedes Modell
					enthält Bauteile, die vereinfacht realen
					Satellitenkomponenten entsprechen.
				</p>
			</div>
		</div>

		<div class="flexbox right">
			<img
				class="itemIMG secondIMG"
				src="/img/Karteikarten_Webseite_alpha.png"
			/>
			<div class="flexboxText">
				<p class="itemHeadline">Umfassende Anleitung</p>
				<p class="itemText secondText">
					Der Satellitenbaukasten wird mit
					Zusatzinformationen und einer klaren und einfachen
					Anleitung für Lehrkörper geliefert, die Schritt
					für Schritt den Zusammenbau der Modelle erklärt.
					Dies ermöglicht eine eigenständige Durchführung
					durch das Lehrpersonal.
				</p>
			</div>
		</div>
		
		<div class="flexbox left">
			<!--              ↓      needs another image -->
			<img
				class="itemIMG fourthIMG"
				src="../assets/stockImage_presentation.jpg"
			/>
			<!--              ↑      needs another image -->
			<div class="flexboxText">
				<p class="itemHeadline">
					Buchbare Sciencetainment-Vorträge
				</p>
				<p class="itemText fourthText">
					Für eine vertiefte und unterhaltsame Erfahrung
					bieten wir die Möglichkeit
					Sciencetainment-Vorträge zu buchen. Unsere
					Expertinnen und Experten gewähren spannende
					Einblicke in die Welt der Raumfahrt und
					Satelliten, indem sie lebendige Vortragseinheiten,
					interaktive Experimente und Diskussionen
					durchführen.
				</p>
			</div>
		</div>

		<div class="flexbox right">
			<img
				class="itemIMG thirdIMG"
				src="../assets/2-Sats-posed.jpg"
			/>
			<div class="flexboxText">
				<p class="itemHeadline">Kauf- oder Mietoption</p>
				<p class="itemText thirdText">
					Wir bieten die Möglichkeit des Kaufs oder der
					Miete der Satellitenmodelle. Dies ermöglicht es,
					das Produkt sowohl langfristig zu besitzen als
					auch kurzfristig für Unterrichtseinheiten,
					Projekttage oder Veranstaltungen zu nutzen.
				</p>
			</div>
		</div>

	</div>
</template>

<script>
import { eventBus } from "@/stores/eventBus";

export default {
	name: "OurProducts",
	components: {},
	mounted() {
		eventBus.$on("scrollTo", this.scrollNavBar);
		const options = {
			root: null, //get full page viewport
			rootMargin: "0px",
			threshold: 0.3, //50% of the element is visible
		};
		const elementsL = document.querySelectorAll(".left");
		const elementsR = document.querySelectorAll(".right");
		const allElements = [...elementsL, ...elementsR];
		let observer = new IntersectionObserver(
			this.handleIntersect,
			options
		);
		allElements.forEach((element) => {
			observer.observe(element);
		});
	},
	beforeDestroy() {
		eventBus.$off("scrollTo", this.scrollNavBar);
	},
	methods: {
		scrollNavBar(sectionID) {
			const element = document.getElementById(sectionID);
			if (element) {
				element.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
			}
		},
		handleIntersect(entries) {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add("fade-in");
				}
			});
		},
	},
};
</script>
<style scoped>


.fade-in {
	animation: fadeIn 1s ease forwards;
	opacity: 0;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.wrapper {
	scroll-margin-top: 130px;
	/* scroll to the right place */
}

.flexbox-wrapper {
	position: relative;
	margin: 5%;
	margin-top: 11%;
	scroll-margin-top: 130px;
}

.flexbox {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 7%;
	flex-wrap: wrap;
	margin: 15% 0;
	opacity: 0;
}

.flexbos.visible {
	opacity: 1;
}

.flexboxText {
	display: flex;
	flex-direction: column;
	width: 45%;
}

.left {
	flex-direction: row;
}

.right {
	flex-direction: row-reverse;
}

.itemIMG {
	width: 35%;
	border-radius: 30px;
}

.secondIMG {
	width: 40%;
}

.itemText {
	font-family: Outfit;
	font-weight: 300;
	font-size: 30px;
	color: white;
}

.angebot{
      margin-top: 0%; 
      font-size: 35px;
      font-weight: 500;
      font-family: Outfit;
      text-align: center;
      color: white;
}

.itemHeadline {
	font-family: Outfit;
	font-weight: 600;
	font-size: 40px;
	color: white;
}

.mobile{
	display: none;
}

hr.rounded {
  border-top: 2px solid #ffffff;
  border-radius: 2px;
}

@media (max-width: 1300px) {
	
	.itemText {
		font-size: 20px;
	}
	.itemHeadline {
		font-size: 24px;
	}
	.flexboxText{
		width: 80%;
	}
	.itemIMG{
		width: 50%;
	}
	.secondIMG{
		border-radius: 0;
	}
	/* .itemText {
		font-size: 15px;
	}
	.itemHeadline {
		font-size: 20px;
	} */
}

@media (max-width: 1050px) {
	.mobile{
		display: block;
	}
	.itemText {
		font-size: 20px;
	}
	.itemHeadline {
		font-size: 24px;
	}
	.flexboxText,
	.itemIMG{
		width: 80%;
	}
	.secondIMG{
		border-radius: 0;
	}
}
</style>
