<template>
      <div id="app">
            <IntroductionPart />
            <NavbarHeader />
            <OurProducts />
            <ProductDescription />
            <ContactInfo />
            <NavbarFooter />
      </div>
</template>

<script>
import NavbarHeader from "./components/NavbarHeader.vue";
import ContactInfo from "./components/ContactInfo.vue";
import NavbarFooter from "./components/NavbarFooter.vue";
import OurProducts from "./components/OurProducts.vue";
import IntroductionPart from "./components/IntroductionPart.vue";

export default {
      name: "App",
      components: {
            ContactInfo,
            NavbarHeader,
            NavbarFooter,
            OurProducts,
            IntroductionPart
      },
};
</script>

<style>
@font-face {
      font-family: "Outfit";
      /* src: url('./assets/fonts/Outfit-VariableFont_wght.ttf'); */
      src: url('../public/fonts/Outfit/Outfit-Regular.woff2') format("woff2"),
            url('../public/fonts/Outfit/Outfit-Regular.woff') format("woff");

}

/* Es könnte so schön sein */
/* :root::-webkit-scrollbar{
  display: none;
} */

:root {
  scrollbar-width: none;
  -ms-overflow-style: none;
}


body {
      min-height: 1000px;
      /*minimum size, so scrollable gradient  */
      background: linear-gradient(180deg,
                  rgb(0, 0, 0) 2%,
                  rgb(28, 0, 170) 100%);
      background-repeat: no-repeat;
      margin: 0px;
      padding: 0px;
      /* overflow-x:hidden; */
}

/* Position Footer Bottom */
NavbarFooter {
      position: absolute;
      bottom: 0;
      left: 0;
      /* margin-top: 10px; */
}

.text {
      font-family: Outfit;
      font-weight: 300;
}

</style>
