<template>
	<div class="modal-wrapper" v-if="show">
		<div class="modal">
			<svg
				class="close"
				@click="close"
				xmlns="http://www.w3.org/2000/svg"
				width="40"
				height="40"
				viewBox="0 0 40 40"
				@mouseover="isHovered = true"
				@mouseleave="isHovered = false"
			>
				<path
					d="M31.6654 10.6834L29.3154 8.33337L19.9987 17.65L10.682 8.33337L8.33203 10.6834L17.6487 20L8.33203 29.3167L10.682 31.6667L19.9987 22.35L29.3154 31.6667L31.6654 29.3167L22.3487 20L31.6654 10.6834Z"
					:fill="isHovered ? '#96B9D3' : 'white'"
				/>
			</svg>
			<div class="modal-content">
				<slot></slot
				><!--   dynamically placeholder -->
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ModalPopUp",
	props: {
		show: Boolean,
	},
	data() {
		return {
			isHovered: false /*change color of svg when hover*/,
		};
	},
	methods: {
		close() {
			this.$emit("close");
			this.enableBodyScroll();
		},

		disableBodyScroll() {
			document.body.style.overflow = "hidden";
		},

		enableBodyScroll() {
			document.body.style.overflow = "auto";
		},
	},
	watch: {
		show(newValue) {
			if (newValue) {
				this.disableBodyScroll();
				this.isHovered = false; //damit beim ersten öffnen direkt weiß
			} else {
				this.enableBodyScroll();
			}
		},
	},
};
</script>

<style scoped>
.modal-wrapper {
	position: fixed;
	z-index: 1100;
	/*  above navbar (1000) */
	/* position on whole page*/
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	/* Position Content Flex*/
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.6);
}

.modal {
	background-color: #232548;
	border-radius: 30px;
	padding: 20px;
	/* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
	max-width: 700px;
	/* limits width of Modal */
	min-width: 500px;
	position: relative;
}

.modal-content {
	font-family: Outfit;
	color: #ffffff;
	margin: 25px 48px 35px 48px;
}

.close {
	position: absolute;
	right: 40px;
	top: 30px;
	cursor: pointer;
	padding: 3px;
	background-color: #070126;
	border-radius: 100%;
}

@media (max-width: 768px) {
	.modal {
		max-width: 300px;
		min-width: 200px;
		padding: 10px;
		/* border-radius: 0; */
	}
	.modal-content {
		margin: 15px 30px 25px 15px;
	}
	.close {
		position: absolute;
		right: 17px;
		top: 17px;
		cursor: pointer;
		padding: 3px;
		background-color: #070126;
		border-radius: 100%;
		width: 30px;
		height: 30px;
	}
}
</style>
