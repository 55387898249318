<template>
      <div class="wrap-wrapper">
            <div class="navbar-wrapper">
                  <div class="navbar">
                        <div class="items item-1" @click="openImpressumModal">
                              Impressum
                        </div>
                        <ModalPopUp
                              :show="showModalImpressum"
                              @close="closeImpressumModal"
                        >
                              <h1>Impressum</h1>
                              <p>
                                    <b
                                          >Spacecraft Education UG
                                          (haftungsbeschränkt)</b
                                    ><br />
                                    Turmstrasse 79<br />
                                    10551 Berlin
                              </p>
                              <a
                                    class="mail"
                                    href="mailto:info@spacecraft-education.com"
                              >
                                    <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                    >
                                          <path
                                                d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 11L4 6H20ZM20 18H4V8L12 13L20 8V18Z"
                                                fill="currentColor"
                                          />
                                    </svg>
                                    <a> info@spacecraft-education.com<br /> </a>
                              </a>
                              <p>
                                    <b>Vertretungsberechtigt:</b><br />
                                    Isabel Pitz, Steffen Reinert
                              </p>
                              <p>
                                    <b>Umsatzsteuer-Identifikationsnummer</b
                                    ><br />

                                    DE 366803208
                              </p>
                              <!-- <p>
                                    <b>Wirtschafts-Identifikationsnummer</b
                                    ><br />
                                    TBD0000000000000
                              </p> -->
                              <p>
                                    <b>Registergericht:</b><br />
                                    Amtsgericht Berlin Charlottenburg
                              </p>
                              <p>
                                    <b>Registernummer</b><br />
                                    HRB 261048
                              </p>
                        </ModalPopUp>

                        <div class="items item-2" @click="openDatenschutzModal">
                              Datenschutz
                        </div>
                        <ModalPopUp
                              :show="showModalDatenschutz"
                              @close="closeDatenschutzModal"
                        >
                              <h1>Datenschutz</h1>
                              <p class="inline">
                                    <a
                                          class="mail inline"
                                          href="/SCE_Datenschutzerklaerung.pdf"
                                          target="_blank"
                                          rel="noreferrer noopener"
                                    >
                                          <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height="24"
                                                width="24"
                                                viewBox="0 -960 960 960"
                                          >
                                                <path
                                                      d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"
                                                      fill="currentColor"
                                                />
                                          </svg>
                                          Hier
                                    </a>
                                    können Sie unsere Datenschutzerklärung
                                    herunterladen.
                              </p>
                        </ModalPopUp>

                        <div class="items item-3" @click="openCookieModal">
                              Cookie Hinweise
                        </div>
                        <ModalPopUp
                              :show="showModalCookie"
                              @close="closeCookieModal"
                        >
                              <h1>Cookie Hinweise</h1>
                              <p>
                                    Unsere Website verwendet
                                    <b><u>keine</u></b> Cookies, um Ihre
                                    Privatsphäre zu respektieren😊<br /><br />Wir
                                    sind bestrebt, Ihren Besuch auf unserer
                                    Website so sicher und anonym wie möglich zu
                                    gestalten.<br />
                                    Ihre persönlichen Daten und Informationen
                                    werden nicht verfolgt oder gesammelt,
                                    während Sie unsere Website besuchen.
                              </p>
                        </ModalPopUp>
                        <!-- <div class="items item-4"
                              @click="openNutzungsbedingungenModal">
                              Nutzungsbedingungen</div>
                        <ModalPopUp :show="showModalNutzungsbedingungen"
                              @close="closeNutzungsbedingungenModal">
                              <h1>Nutzungsbedingungen</h1>
                              <p>Jo kein Dunst. Hier bitte einen tollen Witz
                                    einfügen haha hihi</p>
                        </ModalPopUp> -->
                  </div>
            </div>
      </div>
</template>

<script>
import ModalPopUp from "./ModalPopUp.vue";

export default {
      name: "NavbarFooter",
      components: {
            ModalPopUp,
      },
      data() {
            return {
                  showModalImpressum: false,
                  showModalCookie: false,
                  showModalDatenschutz: false,
            };
      },
      methods: {
            openImpressumModal() {
                  this.showModalImpressum = true;
            },
            closeImpressumModal() {
                  this.showModalImpressum = false;
            },
            openCookieModal() {
                  this.showModalCookie = true;
            },
            closeCookieModal() {
                  this.showModalCookie = false;
            },
            openDatenschutzModal() {
                  this.showModalDatenschutz = true;
            },
            closeDatenschutzModal() {
                  this.showModalDatenschutz = false;
            },
      },
};
</script>

<style scoped>
.navbar-wrapper {
      background-color: rgba(35, 37, 72, 1);
      border-radius: 30px;
      margin: 0px 23px 26px 23px;
}

.navbar {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-content: center;
      align-items: center;
      border-radius: 30px;
      /*wir wollen das fest */
      height: 85.6px;
      /*wir wollen das fest */
      padding-right: 2%;
      padding-left: 3%;
}

.items {
      color: white;
      font-family: Outfit;
      font-size: 30px;
      font-weight: 500;
}

img {
      margin-left: auto;
      width: 190px;
      margin-left: 0%;
      margin-right: 3%;
}

/* ---------------------------HoverEffects--------------------------- */
.items {
      position: relative;
      /* Positionierung des Elternelements für das Pseudoelement */
}

.items:hover::after {
      content: "";
      /* Inhalt des Pseudoelements */
      position: absolute;
      /* Absolut positioniert um relativ zum Elternelement zu sein */
      bottom: -8px;
      left: 20%;
      width: 60%;
      height: 3px;
      border-radius: 2px;
      background-color: #96b9d3;
}

.item-1:hover,
.item-2:hover,
.item-3:hover,
.item-4:hover {
      color: #96b9d3;
      /* text-decoration-line: underline; */
      /* border-bottom: 2px solid #96B9D3; */
      cursor: pointer;
}

.mail {
      font-size: 20px;
      text-decoration: none;
      color: #96b9d3;
      display: flex;
      align-items: center;
      gap: 10px;
}

.mail:hover {
      color: #4e89b5;
      text-decoration-line: underline;
}

/* ---------------------------Modalstyling--------------------------- */

ModalPopUp {
      width: 20%;
      font-size: 24px;
      font-weight: 300;
}

h1 {
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
}

p {
      font-size: 20px;
}

b {
      font-weight: 600;
}

a {
      text-decoration: none;
}


.inline {
      display: inline;
}

/* * {
      outline: solid red;
} */

@media (max-width: 1050px) {
      .navbar-wrapper {
            background-color: rgba(35, 37, 72, 1);
            border-radius: 0px;
            margin: 0%;

      }

      .items {
            color: white;
            font-family: Outfit;
            font-size: 15px;
            font-weight: 500;
      }
      .navbar {
            justify-content: space-evenly;
            height: 60px;
      }
      .item-3 {
            display: inline;
      }
      h1 {
            font-size: 20px;
      }
      p {
            font-size: 15px;
      }
      .mail {
            font-size: 15px;
      }
      svg{
            width: 15px;
            height: 15px;
      }
}
</style>
