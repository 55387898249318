<template>
      <div class="wrapper fade-in" id="introduction">
            <div class="flexbox-slogan">
                  <p class="item-5">"Ein Stück Weltraum in deinen Händen"</p>
            </div>
            <div class="flexbox">
                  <img
                        class="item item-1"
                        src="/img/Satellitenbau_an_Schule.png"
                  />
                  <p class="item item-2">
                        Willkommen in der faszinierenden Welt der Raumfahrt mit
                        unserem Satellitenbaukasten! <br />Unser innovatives
                        Produkt ermöglicht es spielend zu lernen, was Satelliten
                        sind, wie sie aufgebaut sind, und welche Aufgaben sie im
                        Weltraum übernehmen.
                  </p>
            </div>
            <div class="flexbox-2">
                  <img
                        class="item item-4"
                        src="../assets/Student_Favourite.jpg"
                  />
                  <p class="item item-3">
                        Der Satellitenbaukasten besteht aus 3D-gedruckten
                        Satellitenmodellen in Originalgröße, die aus
                        verschiedenen Satellitenkomponenten bestehen und
                        selbstständig zusammengebaut werden können.
                  </p>
            </div>
      </div>
</template>

<script>
import { eventBus } from "@/stores/eventBus";

export default {
      name: "IntroductionPart",
      data() {
            return {};
      },
      components: {},
      mounted() {
            eventBus.$on("scrollTo", this.scrollNavBar);
      },
      beforeDestroy() {
            eventBus.$off("scrollTo", this.scrollNavBar);
      },
      methods: {
            scrollNavBar(sectionID) {
                  const element = document.getElementById(sectionID);
                  if (element) {
                        element.scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                        });
                  }
            },
      },
};
</script>

<style scoped>
.fade-in {
      animation: fadeIn 3s ease forwards;
      opacity: 0;
}

@keyframes fadeIn {
      0% {
            opacity: 0;
      }
      100% {
            opacity: 1;
      }
}

/* * {
      outline: solid red;
} */

.wrapper {
      position: relative;
      margin: 5%;
      scroll-margin-top: 130px;
      margin-top: 11%;
}

.flexbox-slogan,
.flexbox,
.flexbox-2 {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10%;
      flex-wrap: wrap;
      margin: 6% 0;
}

.flexbox-2 {
      flex-direction: row-reverse;
}

.item {
      font-family: Outfit;
      font-weight: 300;
      font-size: 35px;
      color: white;
}

.item-1 {
      width: 25%;
      border-radius: 24px;
}

.item-2 {
      margin-right: 0px;
      margin-left: 0%;
      width: 40%;
}

.item-3 {
      margin-right: 0px;
      width: 40%;
}

.item-4 {
      width: 30%;
      border-radius: 24px;
}

.item-5 {
      width: 100%;
      margin: 0%;

      font-family: Outfit;
      font-style: italic;
      font-weight: 600;
      font-size: 55px;
      color: white;
      text-align: center;
}

@media (max-width: 1300px) {
      .item {
            font-size: 18px;
      }
      .item-5 {
            font-size: 27px;
      }
}

@media (max-width: 1050px) {
      .item {
            font-size: 20px;
      }
      .item {
            width: 70%;
      }
      .item-5 {
            font-size: 26px;
            margin-top: 20px;
            margin-bottom: 20px;
      }
      .wrapper {
            margin-top: 120px;
      }
      .flexbox-2 {
            margin-bottom: 40%;
      }
      /* .item-1,
      .item-4{
            width: 70%;
      } */
}
</style>
