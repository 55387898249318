<template>
  <div class="mainContent" id="contact">
    <div class="flex-container">
      <div class="flex-item text" id="headlineDE">Kontakt</div>

      <div class="flex-item text" id="bulletpointsDE" style="font-weight: 500">
        Sie können uns zu allen Themen kontaktieren!

        <li style="font-weight: 300; margin-top: 20px">Fragen zu dem Produkt</li>
        <li style="font-weight: 300">Miet- und Kaufoptionen</li>
        <li style="font-weight: 300">Personalisierungen</li>
      </div>

      <div class="flex-item text" id="infotextDE">
        Zögern Sie nicht, uns mit Ihren Fragen zu löchern.
      </div>
      <!-- <div
                        class="flex-item text"
                        id="bulletpointsEN"
                        style="font-weight: 500"
                  >
                        You can contact us on every behalf!

                        <li style="font-weight: 300; margin-top:20px;">questions about the product</li>
                        <li style="font-weight: 300">renting or buying options</li>
                        <li style="font-weight: 300">personalizing</li>
                  </div> -->

      <div class="flex-item text" id="contactinfoDE" style="font-weight: 500">
        Kontaktinformationen:

        <li style="font-weight: 300; margin-top: 20px">
          <a class="mail" href="mailto:info@spacecraft-education.com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 11L4 6H20ZM20 18H4V8L12 13L20 8V18Z"
                fill="currentColor"
              />
            </svg>
            <a> info@spacecraft-education.com<br /> </a>
          </a>
        </li>
      </div>
      <!-- 
                  <div
                        class="flex-item text"
                        id="contactinfoEN"
                        style="font-weight: 500"
                  >
                        Contact Info:

                        <li style="font-weight: 300; margin-top: 20px">
                              <a
                                    class="mail"
                                    href="mailto:info@spacecraft-education.com"
                              >
                                    <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                    >
                                          <path
                                                d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 11L4 6H20ZM20 18H4V8L12 13L20 8V18Z"
                                                fill="currentColor"
                                          />
                                    </svg>
                                    <a> info@spacecraft-education.com<br /> </a>
                              </a>
                        </li>
                  </div> -->
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/stores/eventBus";

export default {
  name: "ContactInfo",
  mounted() {
    eventBus.$on("scrollTo", this.scrollNavBar);
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };
    const observer = new IntersectionObserver(
      this.intersectionCallback,
      options
    );
    observer.observe(document.getElementById("contact"));
  },
  beforeDestroy() {
    eventBus.$off("scrollTo", this.scrollNavBar);
  },
  methods: {
    scrollNavBar(sectionID) {
      const element = document.getElementById(sectionID);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
    intersectionCallback(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-in");
        }
      });
    },
  },
};
</script>

<style scoped>
.fade-in {
  animation: fadeIn 2s ease forwards;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.mainContent {
  scroll-margin-top: 130px;
  margin: 10vh;
  margin-top: 30vh;
  opacity: 0;
}

.text {
  font-family: Outfit;
  font-weight: 300;
  font-size: 30px;
  color: white;
}

.flex-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;

  row-gap: 20px;
  /* column-gap: 30px; */

  background-color: #232548;
  border-radius: 30px;

  padding: 40px;
  padding-left: 60px;

  width: 50%;
  margin: auto;
}

.flex-item {
  flex-basis: 40%;
}

.mail {
  font-size: 30px;
  text-decoration: none;
  color: #96b9d3;
  display: flex;
  align-items: center;
  gap: 10px;
}

svg {
  width: 30px;
  height: 30px;
}

.mail:hover {
  color: #4e89b5;
  text-decoration-line: underline;
}

#headlineDE {
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 25px;
}

#contactinfoDE {
  list-style: none;
}

#bulletpointsDE {
  list-style-position: inside;
  list-style-type: square;
  margin-bottom: 20px;
}

@media (max-width: 1050px) {
  .flex-container {
    row-gap: 20px;
    background-color: #232548;
    border-radius: 30px;

    padding: 20px;
    padding-left: 30px;

    width: 80%;
  }
  .mainContent {
    margin: 5% 5%;
  }
  .text {
    font-size: 18px;
  }
  .mail {
    font-size: 18px;
  }
  svg {
    width: 18px;
    height: 18px;
  }
  #headlineDE {
    font-size: 27px;
  }
}
</style>
