<template>
	<div class="wrap-wrapper">
		<div class="navbar-wrapper">
			<div class="navbar">
				<img
					class="item-0 image"
					src="../../public/Logo_Inverted.svg"
				/>
				<div
					@click="scrollToSection('introduction')"
					class="items item-1"
				>
					Einblick
				</div>
				<div
					@click="scrollToSection('products')"
					class="items item-2"
				>
					Angebot
				</div>
				<div
					@click="scrollToSection('contact')"
					class="items item-3"
				>
					Kontakt
				</div>
				<div class="item-4">
					Ein Stück Weltraum <br />
					in deinen Händen
				</div>
				<div class="navbar-toggle">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						height="30"
						viewBox="0 -960 960 960"
						width="30"
					>
						<path
							d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"
							fill="white"
						/>
					</svg>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { eventBus } from "../stores/eventBus.js";

export default {
	name: "NavbarHeader",
	components: {},
	methods: {
		scrollToSection(sectionId) {
			eventBus.$emit("scrollTo", sectionId);
		},
	},
};
</script>

<style scoped>
.wrap-wrapper {
	position: fixed;
	top: 0px;
	z-index: 1000;
	width: 100%;
}

.navbar-wrapper {
	background-color: rgba(35, 37, 72, 0.8);
	margin: 23px 26px;
	border-radius: 30px;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	backdrop-filter: blur(5px);
}

.navbar {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 7%;
	border-radius: 30px; /*wir wollen das fest */
	height: 85.6px; /*wir wollen das fest */
	padding-right: 2%;
	padding-left: 3%;
}

img {
	width: 190px;
	margin-left: 0%;
	margin-right: 0%;
}

.items {
	color: white;
	font-family: Outfit;
	font-size: 30px;
	font-weight: 500;
}

.item-4 {
	color: white;
	text-align: center;
	font-family: Outfit;
	font-size: 21px;
	font-weight: 200;
	margin-left: auto; /*moves to the right */
}

/* ---------------------------HoverEffects--------------------------- */

.items {
	position: relative; /* Positionierung des Elternelements für das Pseudoelement */
}

.items:hover::after {
	content: ""; /* Inhalt des Pseudoelements */
	position: absolute; /* Absolut positioniert um relativ zum Elternelement zu sein */
	bottom: -8px;
	left: 20%;
	width: 60%;
	height: 3px;
	border-radius: 2px;
	background-color: #96b9d3;
}

.item-1:hover,
.item-2:hover,
.item-3:hover {
	color: #96b9d3;
	cursor: pointer;
}

.navbar-toggle{
	display: none;
}

@media (max-width: 1050px) {
	.wrap-wrapper{
		position: absolute;
		/* margin-left: auto;
		margin-right: auto; */
		/* width: 50%; */
	}
	.navbar-wrapper,
	.navbar{
            justify-content: center;
			background-color: rgba(35, 37, 72, 0);
			height: 80px;
	}
	.items,
	.item-4 {
		font-size: 20px;
		display: none;
	}
	img {
		width: 200px;
	}
}
</style>
